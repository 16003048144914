import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Input, Pagination, Dropdown, Select, Menu, Drawer } from "antd";
import {
  Link,
} from "react-router-dom";

import { Format } from "../../common/format";
import { Popup } from "../../common/component";

import MotorbikeDetail from './motorbikeDetail';
import PostMotor from "./post";

import { MotorBikeManageService } from '../motorBike/motorBikeService';
import { CurrentUserService } from "../user/currentUserService";

const Manage = () => {
  const [currentUserState, setCurrentUserState] = useState({
    currentUser: CurrentUserService.getUser()
  });
  const [compState, setCompState] = useState({
    itemList:[],
    pageIndex: 1,
    pageSize: 100,
  });
  const [filterState, setFilterState] = useState({
    pageIndex: 1,
    hired: '',
    kind: '',
    searchKey: '',
  });
  const [itemState, setItemState] = useState({
    isItemDrawer: false
  });
  const [gpsList, setGpsList] = useState([]);

  useEffect(() => {
    const userCurrentSubscription = CurrentUserService.getObservable().subscribe((user) => {
			setCurrentUserState({currentUser: user});
		});

		return () => {
			userCurrentSubscription.unsubscribe();
		}
  }, []);

  useEffect(() => {
    getList();
  }, [filterState]);

  useEffect(() => {
    getGpsList();
  },[]);

  const getGpsList = () => {
    Popup.spin.show();
    MotorBikeManageService.getGpsList().then(res => {
      Popup.spin.hide();
      if (res.success) {
        setGpsList(res.data);
      }
    })
  }

  const getList = () => {
    Popup.spin.show();
    MotorBikeManageService.init({ ...filterState }).then(res => {
      Popup.spin.hide()
      if (res.success) {
        setCompState({
          ...compState,
          itemList: res.itemList,
          pageIndex: res.pageIndex,
          pageSize: res.pageSize,
          totalPages: res.totalPages,
          totalItems: res.totalItems,
        });
      }
    })
  }

  const onSearch = (value) => {
    setFilterState({
      ...filterState,
      searchKey: value
    });
  }

  const onFunctionUpdate = (e) => {
		e.preventDefault();

		Popup.info({
			title: "Tính năng đang tạm khoá",
		});
	};

  const onAddItem = (item) => {
    setItemState({
      item: item,
      isItemDrawer: true,
    });
  }

  const onEditItem = (item) => {
    setItemState({
      itemId: item.id,
      isItemDrawer: true,
    });
  }

  const onItemCancel = () => {
    setItemState({
      ...itemState,
      isItemDrawer: false,
    });
  }

  const onItemOk = () => {
    getList();
    setItemState({
      ...itemState,
      isItemDrawer: false,
    });
  }

  const onViewItem = (item) => {
    setItemState({
      item: item,
      isItemDrawer: true,
    });
  }

  const onViewItemCancel = () => {
    setItemState({
      ...itemState,
      isItemDrawer: false,
    });
  }

  const onViewItemOk = () => {
    setItemState({
      ...itemState,
      isItemDrawer: false,
    });
  };

  const findItemInList = (id, list) => list.find(({ regNo }) => regNo === id);

  const findItemWithDefautValue = (id, list) => {
    if(list == null || list.length == 0){
      return <>
        <em>{'NA'}</em>
      </>
    }
    var item = findItemInList(id, list);
    if(item){
      var color = '';
      switch (item.state) {
        case 1:
          color = 'green'
          break;
        case 3:
          color = 'blue'
          break;
        case 5:
          color = 'black'
          break;
        case 6:
          color = 'red'
          break;
        default:
          break;
      }
      if(item.distanceKmUnit > 30){
        return <>
          <div style={{color: color}}>{item.action}</div>
          <em style={{color: 'red'}}>{Format.number(item.distanceKmUnit, 1) + ' km'}</em> 
        </>
      }
      else {
        return <>
          <div style={{color: color}}>{item.action}</div>
          <em>{Format.number(item.distanceKmUnit, 2) + ' km'}</em>
        </>
      }
    }else{
      return <>
        <em>{'NA'}</em>
      </>
    }
  }

  const onSyncGps = (item) => {
    Popup.spin.show();
    MotorBikeManageService.syncOdometer({extId: item.extId}).then(res => {
      Popup.spin.hide();
      if (res.success) {
        getList();
      }
    })
  }

  const onSyncGpsAll = () => {
    Popup.spin.show();
    MotorBikeManageService.syncOdometerAll().then(res => {
      Popup.spin.hide();
      if (res.success) {
        getList();
      }
    })
  }

  if (!compState) return <></>
  return (
    <React.Fragment>
      <h1>Quản lý xe</h1>
      <Row align="middle">
        <Col>
          <Select
            placeholder="Trạng thái"
            style={{ width: "160px" }}
            value={filterState.hired}
            onChange={(value) => setFilterState({ ...filterState, hired: value })}
          >
            <Select.Option value="">Tất cả trạng thái</Select.Option>
            <Select.Option value="true">Đã thuê</Select.Option>
            <Select.Option value="false">Chưa thuê</Select.Option>
          </Select>
        </Col>
        <Col>
          <Select
            placeholder="Loại xe"
            style={{ width: "160px" }}
            value={filterState.kind}
            onChange={(value) => setFilterState({ ...filterState, kind: value })}
          >
            <Select.Option value="">Tất cả loại xe</Select.Option>
            <Select.Option value="1">Xe ga</Select.Option>
            <Select.Option value="2">Xe số</Select.Option>
            <Select.Option value="3">Xe điện</Select.Option>
          </Select>
        </Col>
        <Col>
          <Input.Search
            placeholder="Biển số xe"
  					onSearch={onSearch}
            enterButton
            allowClear
  				/>
        </Col>
      </Row>
      <Row align="middle" justify="end">
        <Col>Số lượng xe: <span style={{ fontSize: 20 }}>{compState.totalItems}</span></Col>
        <Col style={{ marginLeft: 'auto' }}>
          <Pagination
            current={compState.pageIndex}
            total={compState.totalItems}
            pageSize={compState.pageSize}
            showSizeChanger={false}
            onChange={(value) => setFilterState({ ...filterState, pageIndex: value }, () => getList())}
          />
        </Col>
      </Row>
      <div className="box">
        <div className="table has-left-col has-menu">
          <ol>
            <li>#</li>
            <li>Số xe</li>
            <li>Thay dầu (km)</li>
            <li>Odo <a onClick={() => onSyncGpsAll()}><i className="icon fas fa-sync" style={{color:'green', paddingLeft:'5px'}}></i></a></li>
            <li>Số km</li>
            <li>Trạng thái</li>
            {currentUserState.currentUser && currentUserState.currentUser.canEdit && <li></li>}
          </ol>
          {compState.itemList.map((item, index) => (
            <ul key={item.id}>
              <li className="id-col collapsing">{index + 1}</li>
              <li>
                <Link to={`/motorbike/detail/${item.extId}`}>{item.extId}</Link>
                {/*<a onClick={() => onViewItem(item)}>{item.extId}</a>*/}
                <div>
                  <em>Loại:</em> {item.type}
                </div>
                <div>
                  {findItemWithDefautValue(item.extId, gpsList)}
                </div>
              </li>
              <li>
                <div>{Format.number(item.motorOil)}</div>
                <div><em>{item.motorOilDate ? Format.date(new Date(item.motorOilDate)) : ""}</em></div>
              </li>
              <li>
                <div>{Format.number(item.odometer)} {item.needSyncGps ?<a onClick={() => onSyncGps(item)}><i className="icon fas fa-sync" style={{color:'green', paddingLeft:'5px'}}></i></a>:''}</div>
                <div><em>{item.odometerDate ? Format.date(new Date(item.odometerDate)) : ""}</em></div>
              </li>
              <li>
                <div>{Format.number(item.odometer - item.motorOil)}</div>
              </li>
              <li>
                {item.hired ?
                  <span className="hired">Đã thuê</span>
                  : <span className="not-hired">Chưa thuê</span>}
              </li>
              {currentUserState.currentUser && currentUserState.currentUser.canEdit && 
                <li className="menu-col">
                  <Dropdown
                    placement="bottomRight"
                    trigger={["click"]}
                    overlay={
  										<Menu items={[
                        {
          								key: 'update',
          								label: <a onClick={() => onEditItem(item)}>
          									<span>Cập nhật</span>
          								</a>,
          								icon: <i className="icon fal fa-pen"></i>
          							},
                      ]}>
  										</Menu>
  									}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <i className="far fa-ellipsis-v" />
                    </a>
                  </Dropdown>
                </li>
              }
            </ul>
          ))}
        </div>
      </div>
      <Row gutter={[0, 10]}>
        <Col style={{ marginLeft: "auto" }}>
          <Pagination
            current={compState.pageIndex}
            total={compState.totalItems}
            pageSize={compState.pageSize}
            showSizeChanger={false}
            onChange={(value) => setFilterState({ ...filterState, pageIndex: value }, () => getList())}
          />
        </Col>
      </Row>
      {/*<Drawer
        placement="right"
        onClose={onViewItemCancel}
        visible={itemState.isItemDrawer}
        maskClosable={false}
        destroyOnClose={true}
        width={768}
        className="drawer"
      >
        <MotorbikeDetail item={itemState.item} onCancel={onViewItemCancel} onOk={onViewItemOk} />
      </Drawer>*/}
      <Drawer
        placement="right"
        onClose={onItemCancel}
        visible={itemState.isItemDrawer}
        maskClosable={false}
        destroyOnClose={true}
        width={768}
        className="drawer"
      >
        <PostMotor id={itemState.itemId} onCancel={onItemCancel} onOk={onItemOk} />
      </Drawer>
    </React.Fragment>
  )
}

export default Manage;