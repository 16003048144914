import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Input, Button, Pagination, Dropdown, Select, DatePicker, Drawer } from "antd";
import moment from "moment";

import { Locale } from "../../common/locale";
import { Format } from "../../common/format";
import { Popup, Msg, InputComp } from "../../common/component";


const { RangePicker } = DatePicker;
const today = moment();

const Return = () => {
  
return <>RETURN</>
  

}

export default Return;