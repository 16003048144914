import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import { CurrentUserService } from "../user/currentUserService";
import Header from "./header";

import Login from '../user/login';
import Profile from '../user/profile';
import Manage from '../motorBike/manage';
import Return from '../motorBike/return';
import ManageBill from '../bills/manage';
import Income from "../dashboard/income";
import IncomeMonth from "../dashboard/incomeMonth";
import IncomeYear from "../dashboard/incomeYear";
import ManageRepair from '../motorBike/manageRepair';
import ManageOperatingCost from '../operatingCost/manage';
import Register from "../user/register";
import ManageCommission from "../commission/manage";
import Revenue from "../report/revenue";
import Receivable from "../bills/manageReceivable";
import MotorbikeDetail from "../motorBike/motorbikeDetail";
import ManagePartner from "../partner/manage";
import CompareGrossIncome from "../report/compareGrossIncome";
import Map from "../report/map";
import ManageOrder from "../order/manage"; 

const Layout = () => {
  const [isCurrentUser, setIsCurrentUser] = useState(false);

  const [compState, setCompState] = useState({
		userCurrent: CurrentUserService.getUser()
	});

  useEffect(() => {
		const userCurrentSubscription = CurrentUserService.getObservable().subscribe((user) => {
			setCompState({ userCurrent: user });
		});

		return () => {
			userCurrentSubscription.unsubscribe();
		}
	}, [compState]);

  useEffect(() => {
    CurrentUserService.checkUser().then((res) => {
			if (res) setIsCurrentUser(true);
		});
  }, [isCurrentUser])

  return (
      <section className="layout layout-backend">
        <Header />
        <main className="main">
          <Routes>
            <Route path="/login" element={<Login/>} />
            {compState.userCurrent && compState.userCurrent.userName && !compState.userCurrent.isParnert && 
              <>
                  <Route path="" element={<Income/>}/>
                  <Route path="/login" element={<Login/>} />
                  <Route path="/motobike" element={<Manage/>} />
                  <Route path="/motorbike/detail/:extId" element={<MotorbikeDetail/>}/>
                  <Route path="/invoice" element={<ManageBill/>} />
                  <Route path="/partner" element={<ManagePartner/>}/>
                  <Route path="/profile" element={<Profile/>} />
                  <Route path="/register" element={<Register/>} />
                  <Route path="/income" element={<Income/>}/>
                  <Route path="/incomemonth" element={<IncomeMonth/>}/>
                  <Route path="/incomeyear" element={<IncomeYear/>}/>
                  <Route path="/repair" element={<ManageRepair/>}/>
                  <Route path="/operatingcost" element={<ManageOperatingCost/>}/>
                  <Route path="/commission" element={<ManageCommission/>}/>
                  <Route path="/report/revenue" element={<Revenue/>}/>
                  <Route path="/receivable" element={<Receivable/>}/>
                  <Route path="/report/compareGrossIncome" element={<CompareGrossIncome/>}/>
                  <Route path="/report/map" element={<Map/>}/>
                  <Route path="/order" element={<ManageOrder/>}/>
                  <Route path="/return" element={<Return/>}/>
                </>
              }
              {compState.userCurrent && compState.userCurrent.userName && compState.userCurrent.isParnert && 
                <>
                  <Route path="" element={<ManageCommission/>}/>
                  <Route path="/commission" element={<ManageCommission/>}/>
                  <Route path="/order" element={<ManageOrder/>}/>
                  <Route path="/profile" element={<Profile/>} />
                </>
              }
          </Routes>
        </main>
      </section>
    
  )
}

export default Layout;