import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Button } from "antd";

import { Popup } from "../../common/component";

import { CurrentUserService } from "../user/currentUserService";
import { LoginService, LoginToUserService } from "../user/userService";

import { siderService } from "../../common/siderBase";

const Sider = (props) => {
	const [compState, setCompState] = useState({
		userCurrent: CurrentUserService.getUser()
	});

	useEffect(() => {
		const userCurrentSubscription = CurrentUserService.getObservable().subscribe((user) => {
			setCompState({ userCurrent: user });
		});

		return () => {
			userCurrentSubscription.unsubscribe();
		}
	}, [compState]);

	const onLogout = () => {
		LoginService.logoutSubmit();
		window.location = "/";
	};

	const onFunctionUpdate = (e) => {
		e.preventDefault();

		Popup.info({
			title: "Bạn cần nâng cấp quyền Administration",
		});
	};

	return (
		<React.Fragment>
			<div className="link sider-header">
				<div className="sider-header-wrap">
					<Link to="/" className="site-logo">
						<img src="/image/logo.png" alt="" />
					</Link>
				</div>
			</div>
			{compState.userCurrent && compState.userCurrent.userName && !compState.userCurrent.isParnert && (
				<Menu
					className="sider-menu"
					defaultOpenKeys={["manage", "overview", "account"]}
					mode="inline"
					inlineIndent={20}
					onSelect={() => siderService.visible()}
					items={[
			{
				key: 'manage',
				children: [
					{
						key: 'motobike',
						label: <Link to={"/motobike"}>
							<span>Xe máy</span>
						</Link>,
						icon: <i className="icon fal fa-motorcycle"></i>
					},
					{
						key: 'invoice',
						label: <Link to={"/invoice"}>
							<span>Hóa đơn</span>
						</Link>,
						icon: <i className="icon fal fa-file-invoice"></i>
					},
					{
						key: 'return',	
						label: <Link to={"/return"}>
							<span>Trả xe</span>
						</Link>,
						icon: <i className="icon fal fa-undo-alt"></i>
					},
					{
						key: 'receivable',
						label: <Link to={"/receivable"}>
							<span>Công nợ</span>
						</Link>,
						icon: <i className="icon fal fa-file-invoice-dollar"></i>
					},
					{
						key: 'commission',
						label: <Link to={"/commission"}>
							<span>Hoa hồng</span>
						</Link>,
						icon: <i className="icon fal fa-badge-dollar"></i>
					},
					{
						key: 'order',
						label: <Link to={"/order"}>
							<span>Lịch đặt</span>
						</Link>,
						icon: <i className="icon fa fa-calendar"></i>
					},
					{
						key: 'partner',
						label: <Link to={"/partner"}>
							<span>Đối tác</span>
						</Link>,
						icon: <i className="icon fal fa-hands-helping"></i>
					},
					{
						key: 'repair',
						label: <Link to={"/repair"}>
							<span>Bảo trì</span>
						</Link>,
						icon: <i className="icon fal fa-tools"></i>
					},
					{
						key: 'operatingcost',
						label: <Link to={"/operatingcost"}>
							<span>Chí phí hoạt động</span>
						</Link>,
						icon: <i className="icon fal fa-dollar-sign"></i>
					},
				],
				label: <span>
					<span>Quản lý</span>
				</span>
			},
			{
				key: 'overview',
				children: [
					{
						key: 'salesDay',
						label: <Link to={"/income"}>
							<span>Doanh thu ngày</span>
						</Link>,
						icon: <i className="icon fal fa-money-check-edit-alt"></i>
					},
					{
						key: 'salesMonth',
						label: <Link to={"/incomemonth"}>
						<span>Doanh thu tháng</span>
					</Link>,
						icon: <i className="icon fad fa-money-check-edit-alt"></i>
					},
					{
						key: 'salesYear',
						label: <Link to={"/incomeyear"}>
						<span>Doanh thu năm</span>
					</Link>,
						icon: <i className="icon fas fa-money-check-edit-alt"></i>
					},
				],
				label: <span>
					<span>Tổng quan</span>
				</span>
			},
			{
				key: 'advance',
				children: [
					{
						key: 'revenue',
						label: <Link to={"/report/revenue"}><span>Tỷ lệ lợi nhuận</span></Link>,
						icon: <i className="icon fal fa-handshake-alt"></i>
					},
					{
						key: 'compareGrossIncomeDateInMonth',
						label: <Link to={"/report/compareGrossIncome"}><span>So sánh doanh thu</span></Link>,
						icon: <i className="icon fal fa-chart-line"></i>
					},
					{
						key: 'regularpartner',
						label: <span onClick={onFunctionUpdate}>Đối tác thường xuyên</span>,
						icon: <i className="icon fal fa-handshake-alt"></i>
					},
					{
						key: 'regularcustomer',
						label: <span onClick={onFunctionUpdate}>Khách hàng quay lại</span>,
						icon: <i className="icon fal fa-undo-alt"></i>
					},
					{
						key: 'densitycustomer',
						label: <span onClick={onFunctionUpdate}>Mật độ khách hàng</span>,
						icon: <i className="icon fal fa-user-chart"></i>
					},
					{
						key: 'densitymotorbike',
						label: <span onClick={onFunctionUpdate}>Mật độ xe sử dụng</span>,
						icon: <i className="icon fal fa-motorcycle"></i>
					},
					{
						key: 'predictcustomer',
						label: <span onClick={onFunctionUpdate}>Dự đoán khách hàng</span>,
						icon: <i className="icon fal fa-analytics"></i>
					},
					{
						key: 'map',
						label: <Link to={"/report/map"}><span>Bản đồ</span></Link>,
						icon: <i className="icon fal fa-map"></i>
					},
				],
				label: <span>
					<span>Phân tích dữ liệu</span>
				</span>
			},
			{
				key: 'account',
				children: [
					compState.userCurrent.isAdmin && {
						key: 'login', label: <div>
							<Button type="primary" block>
								{compState.userCurrent.userName} Login
							</Button>
						</div>
					},
					compState.userCurrent.isAdmin && {
						key: 'register', label: <Link to={"/register"}>
							<span>Đăng ký thành viên</span>
						</Link>,
						icon: <i className="icon fal fa-user-alt"></i>
					},
					{
						key: 'profile', label: <Link to={"/profile"}>
							<span>Thông tin của bạn</span>
						</Link>,
						icon: <i className="icon fal fa-user-alt"></i>
					}
				],
				label: <span>
					<span>Tài khoản</span>
				</span>
			}
		]}
			>
			</Menu>
			)}

			{compState.userCurrent && compState.userCurrent.userName && compState.userCurrent.isParnert && (
				<Menu
					className="sider-menu"
					defaultOpenKeys={["manage", "overview", "account"]}
					mode="inline"
					inlineIndent={20}
					onSelect={() => siderService.visible()}
					items={[
						{
							key: 'manage',
							children: [
								{
									key: 'commission',
									label: <Link to={"/commission"}>
										<span>Hoa hồng</span>
									</Link>,
									icon: <i className="icon fal fa-badge-dollar"></i>
								},
								{
									key: 'order',
									label: <Link to={"/order"}>
										<span>Lịch đặt</span>
									</Link>,
									icon: <i className="icon fa fa-calendar"></i>
								},
							],
							label: <span><span>Quản lý</span></span>
						},
						{
							key: 'account',
							children: [
								{
									key: 'profile', label: <Link to={"/profile"}>
										<span>Thông tin của bạn</span>
									</Link>,
									icon: <i className="icon fal fa-user-alt"></i>
								}
							],
							label: <span><span>Tài khoản</span></span>
						}
					]}
				>

				</Menu>
			)
			}
		</React.Fragment>
	)
}

export default Sider;